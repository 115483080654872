<template>
  <section>
    <TitleBar>
      Echantillons moisson
      <template #toolbar-content>
        <HelpAnalyseMoissonComponent></HelpAnalyseMoissonComponent>
        &nbsp;
        <TableButtonGroup
          :tableKey="tableKey"
          @rechercher="rechercher"
        ></TableButtonGroup>
      </template>
    </TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <StrapiEditoComponent></StrapiEditoComponent>
      <PaginatedTable
        :fields="fields"
        :busy="busy"
        :pageable="pageable"
        :page="page"
        @change="pageableChanged"
      >
        <template #mobile-card="{ item }">
          <AnalyseMoissonCard :item="item"></AnalyseMoissonCard>
        </template>
        <template #cell(culture)="{ item }">
          {{ getLibelleProduit(item.codeProduit) }}
        </template>
        <template #cell(quantiteContrat)="{ item }">
          <MontantComponent :value="item.quantiteContrat"></MontantComponent>
        </template>
        <template #cell(datePrelevement)="{ item }">
          {{ isoSqlDateToFrenchDate(item.datePrelevement) }}
        </template>
        <template #cell(dateAnalyse)="{ item }">
          {{ isoSqlDateToFrenchDate(item.dateAnalyse) }}
        </template>
        <template #cell(tauxHumidite)="{ item }">
          <MontantComponent :value="item.tauxHumidite"></MontantComponent
        ></template>
        <template #cell(tauxImpurete)="{ item }">
          <MontantComponent :value="item.tauxImpurete"></MontantComponent
        ></template>
        <template #cell(poidsSpecifique)="{ item }">
          <MontantComponent :value="item.poidsSpecifique"></MontantComponent
        ></template>
        <template #cell(fichier)="{ item }">
          <DownloadComponent
            v-if="item.pieceJointe"
            :typeDocument="typeDocument"
            :targetId="item.idKerhis"
          ></DownloadComponent>
        </template>
      </PaginatedTable>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import HelpAnalyseMoissonComponent from "../../components/controls/HelpAnalyseMoissonComponent.vue";
import TableButtonGroup from "../../components/controls/TableButtonGroup.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import StrapiEditoComponent from "../../components/strapi/StrapiEditoComponent.vue";
import PaginatedTable from "../../components/PaginatedTable.vue";
import AnalyseMoissonCard from "../../components/cards/AnalyseMoissonCard.vue";
import MontantComponent from "../../components/MontantComponent.vue";
import DownloadComponent from "../../components/controls/DownloadComponent.vue";
import ExploitationService from "../../services/exploitation.service";
import { TableKeys } from "../../store/tables.defaults";
import {
  dataFactory as tableDataFactory,
  created,
  computed as tableComputed,
  methods as tableMethods,
} from "../../services/tables.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "AnalysesMoissonView",
  components: {
    TitleBar,
    HelpAnalyseMoissonComponent,
    TableButtonGroup,
    ErrorComponent,
    StrapiEditoComponent,
    PaginatedTable,
    AnalyseMoissonCard,
    MontantComponent,
    DownloadComponent,
  },
  data() {
    return {
      ...tableDataFactory(TableKeys.ANALYSES_MOISSON),
      typeDocument: this.$store.state.expl.typesDocument.ANALYSE_MOISSON,
      error: null,
      page: null,
    };
  },
  computed: {
    ...tableComputed,
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
  },
  created,
  mounted() {
    this.load();
  },
  methods: {
    ...tableMethods,
    isoSqlDateToFrenchDate: UtilsService.isoSqlDateToFrenchDate,
    async load() {
      this.error = null;

      // On ne garde que les contrats avec résultat d'analyse
      let payload = this.getSearchPayload();
      payload.genericFilters = {
        operation: "$and",
        operands: [
          { property: "fichierIndexe.idFichier", operation: "$notNull" },
          payload.genericFilters,
        ],
      };

      try {
        this.busy = true;
        let response = await ExploitationService.getContratsApport(
          this.exploitationCourante.codeTiers,
          payload
        );
        this.page = response.data;
      } catch (error) {
        this.page = null;
        this.error = UtilsService.handleError(error);
      } finally {
        this.busy = false;
      }
    },
    getLibelleProduit(codeProduit) {
      return this.$store.getters["ref/produitCereale"](codeProduit).libelle;
    },
  },
};
</script>
